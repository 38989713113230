<template>
  <div id="main">
    <div class="login d-flex align-items-center">
      <div class="w-100">
        <div class="text-center">
          <b-spinner v-if="isSending" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <b-alert v-if="errorMessage" show variant="danger" v-html="errorMessage"></b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LocalStoreService from "../../service/LocalStoreService";
import AuthService from "../../service/auth.service";

export default {
  name: "RedirectPage",
  data() {
    return {
      errorMessage: "",
      isSending: true,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    const redirectUrl = this.$route.query.u;
    if(this.user) {
      this.doRedirect(this.user, redirectUrl);
      return;
    }
    this.authorizeWithTokenCookie(redirectUrl);
  },
  methods: {
    authorizeWithToken(token, redirectUrl) {
      if(!token){ 
        this.errorMessage = "Invalid Url";
        return;
      }
      this.isSending = true;
      this.errorMessage = "";
      AuthService.getUserInfo(token)
        .then(response => {
          if(response) {
            console.log(response.data);
            const loggedUser = response.data;
            loggedUser.token = token;

            this.doRedirect(loggedUser, redirectUrl);
          }
        }).catch(reason => {
          const data = reason.response.data;
            if (data) {
              this.errorMessage = data.message;
            } else {
              this.errorMessage = "Invalid Url";
            }
        }).finally(() => {
          this.isSending = false;
        })
    },
    authorizeWithTokenCookie(redirectUrl) {
      let token = Vue.$cookies.get('token');
      if(!token){ 
        this.errorMessage = "Invalid Url";
        return;
      }
      this.isSending = true;
      this.errorMessage = "";
      AuthService.getUserInfo(token)
        .then(response => {
          if(response) {
            console.log(response.data);
            const loggedUser = response.data;
            loggedUser.token = token;

            this.doRedirect(loggedUser, redirectUrl);
          }
        }).catch(reason => {
          const data = reason.response.data;
            if (data) {
              this.errorMessage = data.message;
            } else {
              this.errorMessage = "Invalid Url";
            }
        }).finally(() => {
          this.isSending = false;
        })
    },
    doRedirect(user, redirectUrl) {
      if(!user) return
      if (user.token) {
        LocalStoreService.setUser(user);
      }
      if(redirectUrl) {
        this.$router.push(redirectUrl);
      }
      else if (user.isGlobal) {
        this.$router.push("/");
      } else {
        this.$router.push("/survey");
      }
    },
    
  },
};
</script>
<style scoped>
#main {
  background-color: #ffffff;
}
.login {
  margin: auto;
  width: 450px;
  height: 100vh;
}
.w-100 {
  width: 100%;
}
.title {
  width: 100%;
}
.btn {
  width: 100%;
}
.main-form {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0px 40px 40px;
}
</style>
